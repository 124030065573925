import { eventEmit } from '@/services/utils/utils.service'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

import { colorsWithLabel } from '@/services/utils/utils.service'

// Plugins
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize'
import UnderlinedPlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import LetterCase from '@ckeditor/ckeditor5-letter-case/src/letter-case'
import LetterSpacing from '@ckeditor/ckeditor5-letter-spacing/src/letter-spacing'
import PersonalizationFields from '@ckeditor/ckeditor5-personalization-fields/src/personalization-fields'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'

// Custom plugin
import FontColorPlugin from '@ckeditor/ckeditor5-font-color-picker/src/fontcolor'

// Configs
import colorsPallet from '@/assets/config/default-style/colors.conf'
import textDefaultStyle from '@/assets/config/default-style/text.conf'
import userConfig from '@/assets/config/user/user.conf'

// Services
import i18n from '@/plugins/i18n'
import { globalstates } from '@/services/states/states.service'

// Libraries
import {
  get as _get,
  orderBy as _orderBy
} from 'lodash-es'

const fonts = _orderBy([
  ...userConfig.fonts.map(f => f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, arial` : `${f.name}, arial`),
  ...textDefaultStyle.fonts.imported.map(f => {
    const fallback = f.custom_fallback ? f.custom_fallback : 'arial'
    return f.name.includes('-') ? `${f.name.replaceAll('-', ' ')}, ${f.name}, ${fallback}` : `${f.name}, ${fallback}`
  }),
  ...textDefaultStyle.fonts.native
])

export default {
  heightAuto: true,
  maxWidthPixel: 1000,
  tempHeight: '42px',
  fontFamily: {
    options: [
      'default',
      ...fonts
    ]
  },
  loading: false,
  revertReadingDirection: false,
  editor: ClassicEditor,
  editorConfig: {
    language: {
      content: i18n.locale
    },
    plugins: [
      EssentialsPlugin,
      UnderlinedPlugin,
      FontColorPlugin,
      ParagraphPlugin,
      FontSizePlugin,
      ItalicPlugin,
      LetterCase,
      PersonalizationFields,
      BoldPlugin,
      Superscript,
      PasteFromOffice,
      Strikethrough,
      LetterSpacing
    ],
    letterCase: {
      translation: {
        upperCase: i18n.t('t_upperCase_'),
        lowerCase: i18n.t('t_lowerCase_'),
        none: i18n.t('t_reset_'),
        tooltip: i18n.t('t_letter_case_')
      }
    },
    personalizationFields: {
      cb: eventEmit,
      translation: {
        tooltip: i18n.t('t_personalization_fields_tooltip_'),
      },
    },
    fontSize: {
      options: [
        'default',
        8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28
      ]
    },
    fontColor: {
      exactColors: _get(globalstates, 'user').configLoaded && userConfig.colors.length
        ? colorsWithLabel(userConfig.colors)
        : colorsWithLabel(colorsPallet.default),
      columns: 8
    },
    letterSpacing: {
      translation: {
        default: i18n.t('t_default_')
      }
    },
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'fontSize',
        'FontColor',
        'LetterCase',
        'LetterSpacing',
        '|',
        'PersonalizationFields',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'superscript'
      ]
    }
  }
}
